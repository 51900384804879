@use "../abstracts/mixins" as *;

.intro {
  gap: 12rem;
  align-items: center;
  justify-content: center;

  @include respond(600) {
    text-align: center;
    gap: 6.4rem;
  }

  &__heading {
    font-size: 6rem;
    font-weight: 700;

    @include respond(600) {
      padding: 6rem 8rem;
    }
  }
}
