@use "../abstracts/mixins" as *;

.categories {
  gap: 3rem;

  &__heading {
    font-size: 4.8rem;
    text-align: center;

    @include respond(600) {
      font-size: 3.6rem;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.4rem;
    flex: 1;

    @include respond(600) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      gap: 2rem;
    }
  }

  &__icon {
    height: 3rem;
    width: 3rem;
    z-index: 10;
  }

  &__btn {
    padding: 1.8rem 3.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.8rem;
    color: #000;

    @include respond(600) {
      padding: 0 1rem;
      text-align: center;
    }
  }

  &__label {
    font-size: 1.8rem;

    @include respond(600) {
      font-size: 1.2rem;
    }
  }
}
