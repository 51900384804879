@use "../abstracts/mixins" as *;

.btn {
  font-family: inherit;
  color: inherit;
  font-weight: 400;
  border-radius: 1.8rem;
  border: none;
  box-shadow: 0rem 0.5rem 1.2rem rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  text-decoration: none;
}

.btn:hover {
  cursor: pointer;
  box-shadow: 0rem 1rem 2.4rem rgba(0, 0, 0, 0.2);
}

.btn:active {
  box-shadow: 0rem 0.5rem 1.2rem rgba(0, 0, 0, 0.1);
}

.btn--large {
  font-size: 2.4rem;
  padding: 4.4rem 6.4rem;
}

.btn--gradient {
  background-image: var(--gradient-button);
  color: var(--color-text-primary);
  position: relative;
  overflow: hidden;
}

.btn--grey {
  background-color: var(--color-grey-light);
  overflow: hidden;
  position: relative;
}

.btn--gradient span,
.btn--grey span {
  z-index: 3;
  position: relative;
}

.btn--gradient:before,
.btn--grey:before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.3s;
}

.btn--gradient:before {
  background-color: rgba(255, 255, 255, 0.2);
}

.btn--grey:before {
  background-image: var(--gradient-button);

  @include respond(600) {
    display: none;
  }
}

.btn--gradient:hover::before,
.btn--grey:hover::before {
  opacity: 1;
}

.btn--top {
  font-size: 1.8rem;
  padding: 1.2rem 3rem;
}

.wrapper-top-btns {
  display: flex;
  position: absolute;
  top: 1.8rem;
  left: 1.8rem;
  gap: 1.8rem;

  @include respond(600) {
    position: static;
    justify-content: center;
    gap: 4rem;
  }
}

.btn-break {
  @include respond(600) {
    display: none;
  }
}

.btn-new-game {
  @include respond(600) {
    margin-bottom: 8rem;
  }
}
