.layer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0;
  transition: opacity 1s;
}

.layer--active {
  opacity: 1;
}
